import { Box, Typography,Grid , Button} from "@mui/material";
import React from 'react';
import theme from "../../styles/theme";

import Education from './Education.js';
import Experience from './Experience.js';
import Skills from './Skills.js';
import AnchorLink from "react-anchor-link-smooth-scroll";

function About()
{
     return (
        <>
        <section 
        id="about"
            theme={theme}
            style={{ backgroundColor:theme.palette.background.primary,
                     paddingTop: '30px'
                  }}
                     >
      <Box padding='40px'>
          <Typography
              variant="h3"
              paddingBottom='20px'
              style={{fontFamily:'Roboto'}}
             >
                About me
            </Typography>
            <Typography>
            Hi there! My name is Barbora Plašovská, and I am a computer science student currently studying at EPITA in France.
            Currently in the second year of the engineering cycle (Master 1 equivalent), in the major MTI specialising in mobile development.
            Originally from the Czech Republic, I moved to Paris to study at EPITA thanks to the encouragement of my beloved
            French teacher. In addition to my studies, I have a strong background in figure skating, having competed for 13 years.
            Nowadays, I enjoy running and staying active. My passion for technology was influenced by my father and brother, both
            of whom are in IT. With their support, I have pursued my interests in this field, gaining experience in Swift, C++,
            JavaScript, React and more through various projects and internships. I am excited to continue exploring new technologies
            and pursuing my career in the exciting and ever-evolving world of computer science. <br/>
            I am <span style={{fontWeight:'bold'}}>actively looking for a 6-month end of studies internship starting February 2025 in ios development</span>,
            as I am very interested in working with SwiftUI. Graduating and obtaining my engineering diploma in Computer
            Science from EPITA in July 2025, I am eager to apply my knowledge and skills in
            SwiftUI to contribute to innovative projects in the field of iOS development.
            Don't hesitate to check out my <AnchorLink href='#portfolio' underline="hover" style={{color: '#972C2D', textDecoration: 'none'}}>personal projects </AnchorLink> 
            in the portfolio section.
          </Typography>
          
      </Box>
    </section>
    <section
    id='subsection-about'
      style={{ backgroundColor:'#ffffff',
      }}
      >
        
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={6}>
                       
                        <Experience/>
                  </Grid>
                  <Grid item xs={6}>
                        <Education/>
                        <Skills/>
                        <Box
                            sx={{
                                paddingTop: '40px',
                                paddingBottom: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center' ,
                                justifyItems: 'center',
                                textAlign:'center',
                                }}>
                            <Button
                                href="/barbora.plasovska.pdf"
                                target="_blank"
                                sx={{
                                    backgroundColor: '#972C2D',
                                    ":hover": {
                                    backgroundColor: '#B5474B',
                                    },
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                    }}>
                                    Download PDF
                            </Button>
                        </Box>
                  </Grid>
          </Grid>
    </section>
    </>
    );
}

export default About;