import { Box, AppBar, Toolbar, Button } from "@mui/material";
import React from 'react';
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useMediaQuery } from 'react-responsive';

import DrawerComponent from "./DrawerComponent";


function HomeNavBar()
{
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
 
    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
            <Toolbar variant="regular"> 
                <Box sx={{ flex: 1}}>
                    <AnchorLink href='#hero' style={{textDecoration: 'none'}}>
                        <img src="logo.png" alt="logo" height="50px"/>
                    </AnchorLink>
                </Box>
                {isMobile ? (
                        <DrawerComponent />
                ) : (
                    <>
                        <Box sx={{ }}>
                            <AnchorLink href='#about' style={{textDecoration: 'none'}}>
                                <Button sx={{color: '#ffffff',  textTransform: 'none', fontSize: '18px'}}>
                                    about
                                </Button>
                            </AnchorLink>
                        </Box>
                        <Box sx={{ }}>
                            <AnchorLink href='#portfolio' style={{textDecoration: 'none'}}>
                                <Button sx={{color: '#ffffff',  textTransform: 'none', fontSize: '18px'}}>
                                    portfolio
                                </Button>
                            </AnchorLink>
                        </Box>
                        <Box sx={{ }}>
                            <AnchorLink href='#contact' style={{textDecoration: 'none'}}>
                                <Button sx={{color: '#ffffff',  textTransform: 'none', fontSize: '18px'}}>
                                    contact
                                </Button>
                            </AnchorLink>
                        </Box>
                    </>
                )}
            </Toolbar>
        </AppBar>
        </Box>
    );
}

export default HomeNavBar;