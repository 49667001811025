import { Box, Typography, Link } from "@mui/material";
import React from 'react';

function Experience()
{
    return (
      <Box padding='40px'>
          <Typography
              variant="h3"
              paddingBottom='20px'>
                Experience
          </Typography> 

          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              <Link href="https://www.epita.fr" target="_blank" underline="hover" style={{color: '#972C2D'}}>
                <span style={{color: '#972C2D'}}>EPITA</span>
              </Link>
              {' | Teaching assistant (YAKA)'}
          </Typography>
          <Typography>
            September 2023 - PRESENT
          </Typography>
          <Typography
            paddingBottom='20px'>
            • Accompanying third year Epita students in 
            their programming classes, answering their 
            questions<br/>
            • Responsible of the JAVA atelier and JWS project
          </Typography>

          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              <Link href="https://www.shadow.tech" target="_blank" underline="hover" style={{color: '#972C2D'}}>
                <span style={{color: '#972C2D'}}>Shadow</span>
              </Link>
              {' | Software engineer intern'}
          </Typography>
          <Typography>
            September 2023 - January 2024
          </Typography>
          <Typography
            paddingBottom='20px'>
            • Worked on ShadowUSB for android <br/>
            • Technologies: Kotlin, C++, CMake, Djinni
          </Typography>

          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              <Link href="https://www.epita.fr" target="_blank" underline="hover" style={{color: '#972C2D'}}>
                <span style={{color: '#972C2D'}}>EPITA</span>
              </Link>
              {' | Teaching assistant C & Rust (ASM)'}
          </Typography>
          <Typography>
            September 2022 - June 2023
          </Typography>
          <Typography
            paddingBottom='20px'>
            • Accompanying second year Epita students in 
            their programming classes, answering their 
            questions<br/>
            • Teaching the basics of C and Rust
          </Typography>

          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              <Link href="https://gdsc.community.dev/epita/" target="_blank" underline="hover" style={{color: '#972C2D'}}>
                <span style={{color: '#972C2D'}}>GOOGLE DEVELOPER STUDENT CLUB EPITA</span>
              </Link>
              {' | Mobile dev lead'}
          </Typography>
          <Typography>
            September 2022 - July 2023
          </Typography>
          <Typography
            paddingBottom='20px'>
            • Organise workshops and presentations on the topic of 
            mobile development using Flutter and Dart
          </Typography>

          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              <Link href="https://www.sharelock.co/" target="_blank" underline="hover" style={{color: '#972C2D'}}>
                <span style={{color: '#972C2D'}}>SHARELOCK</span>
              </Link>
              {' | Full-stack developer intern'}
          </Typography>
          <Typography>
            May 2022 - July 2022
          </Typography>
          <Typography
            paddingBottom='20px'>
            • Worked side by side with experienced developers in a
            French startup (web dev and mobile dev)<br/>
            • Technologies: JavaScript, TypeScript, React native,
            React, Strapi
          </Typography>

          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              <span style={{color: '#972C2D'}}>CANADIAN STARTUP </span>
              | Flutter developer intern
          </Typography>
          <Typography>
            June 2020 - September 2020
          </Typography>
          <Typography
            paddingBottom='20px'>
            • Development of an application: GoodSprout used to remind
            people to water their plants<br/>
            • Technologies: Flutter, Dart, Firebase, SQLite <br/>
            • full remote
          </Typography>
      </Box>
    );
}

export default Experience;