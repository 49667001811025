import React from 'react';
import NavBar from '../../../components/NavBar/NavBar';
import { Box, Typography, Grid} from "@mui/material";
import theme from "../../../styles/theme";
import Contact from '../../Contact/Contact';
import { useMediaQuery } from 'react-responsive';


const SupportPage = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

  return (
    <>
      <NavBar />
      <section id="supportpage" theme={theme} style={{ paddingTop: '30px' }}>
          <Box padding='40px'>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              {!isMobile ?
              <>
                  <Grid item xs={3}
                          sm={5}
                          md={8}>
                    <Typography variant="h3" paddingBottom='20px' paddingTop='30px'>
                      Support for BeeBetter
                    </Typography>
                    <Typography>
                      BeeBetter is your personal habit tracker! Whether you're trying to build new habits or break old ones, BeeBetter makes it easy and fun. With a simple and intuitive interface, you can track your progress and stay motivated to reach your goals.
                    </Typography>
                    <Typography>
                      For any support or inquiries, please feel free to reach out to us.
                    </Typography>
                </Grid> 
                <Grid item xs={1}
                          sm={3}
                          md={4}>
                  <img src={process.env.PUBLIC_URL + "/projects/beebetter/beebetter-thumbnail.png"} alt="beebettericon" height="350px" />
                </Grid>
              </>
              : 
              <>
                <Grid item xs={4}
                        sm={4}
                        md={6}>
                    <Typography variant="h3" paddingBottom='20px' paddingTop='30px'>
                      Support for BeeBetter
                    </Typography>
                    <Typography>
                    BeeBetter is your personal habit tracker! Whether you're trying to build new habits or break old ones, BeeBetter makes it easy and fun. With a simple and intuitive interface, you can track your progress and stay motivated to reach your goals.
                    </Typography>
                    <Typography>
                      For any support or inquiries, please feel free to reach out to us.
                    </Typography>
                </Grid> 
                <img src={process.env.PUBLIC_URL + "/projects/beebetter/beebetter-thumbnail.png"} alt="beebettericon" height="200px" />
              </>
              }
          </Grid>
        </Box>
      </section>
      <Contact />
    </>
  );
};

export default SupportPage;