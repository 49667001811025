import { Box, Typography, Grid } from "@mui/material";
import React from 'react';


const skills = ["SwiftUI", "SwiftData", "CoreData", "Flutter", "Dart", "C++", "C", "Git"];

function Skills() {
    return (
        <Box paddingTop='40px' paddingRight='40px' paddingLeft='40px'>
            <Typography variant="h3" paddingBottom='20px'>
                Skills
            </Typography>
            <Grid container spacing={2} justifyContent="start">
                {skills.map((skill) => (
                    <Grid item key={skill} style={{ textAlign: 'center' }}>
                        <Typography
                            variant="body2"
                            style={{
                                backgroundColor: '#f0f0f0', // Light gray background
                                color: '#666666', // Gray text
                                padding: '10px 20px', // Larger padding for bigger rectangles
                                borderRadius: '8px', // Rounded corners
                                fontWeight: 500,
                            }}>
                            {skill}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Skills;