import { Box, Typography } from "@mui/material";
import React from 'react';

function Education()
{
    return (
      <Box paddingTop='40px' paddingLeft='40px'>
          <Typography
            variant="h3"
            paddingBottom='20px'>
              Education
          </Typography>
          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              EPITA
          </Typography>
          <Typography>
            September 2020 - PRESENT
          </Typography>
          <Typography>
            • school of Engineering and computer science <br/>
            • currently in second year of engineering cycle (Master 1 equivalent) <br/>
            • major MTI specialising in mobile development
          </Typography>
      </Box>
    );
}

export default Education;