import React, { useState } from "react";

import { Drawer, List, ListItem, ListItemText, IconButton, Button } from '@mui/material';
import AnchorLink from "react-anchor-link-smooth-scroll";

import MenuIcon from "@mui/icons-material/Menu";

import theme from "../../styles/theme";


export default function DrawerComponent() {

  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer
        open={openDrawer}
        anchor='right'
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
                <AnchorLink href='#about' style={{textDecoration: 'none'}}>
                    <Button sx={{color: '#111111',  textTransform: 'none', fontSize: '18px'}}>
                        about
                    </Button>
                </AnchorLink>
            </ListItemText>
          </ListItem>
          
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
                <AnchorLink href='#portfolio' style={{textDecoration: 'none'}}>
                    <Button sx={{color: '#111111',  textTransform: 'none', fontSize: '18px'}}>
                        portfolio
                    </Button>
                </AnchorLink>
            </ListItemText>
          </ListItem>
          
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
                <AnchorLink href='#contact' style={{textDecoration: 'none'}}>
                    <Button sx={{color: '#111111',  textTransform: 'none', fontSize: '18px'}}>
                        contact
                    </Button>
                </AnchorLink>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton
        color={theme.palette.background.primary}
        onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon sx={{ color: 'white' }}/>
      </IconButton>
    </>
  );
}