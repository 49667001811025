import React from 'react';
import NavBar from '../../../components/NavBar/NavBar';
import { Box, Typography, Grid} from "@mui/material";
import theme from "../../../styles/theme";
import Contact from '../../Contact/Contact';
import { useMediaQuery } from 'react-responsive';


const SupportPage = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

  return (
    <>
      <NavBar />
      <section id="supportpage" theme={theme} style={{ paddingTop: '30px' }}>
          <Box padding='40px'>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} >
              {!isMobile ?
              <>
                  <Grid item xs={2}
                          sm={4}
                          md={6}>
                    <Typography variant="h3" paddingBottom='20px' paddingTop='30px'>
                      Support for Stitch Counter
                    </Typography>
                    <Typography>
                      Stitch Counter is a versatile app designed for knitting, crocheting, and any other counting needs. Whether you're keeping track of stitches in your latest project or counting inventory in your business, Stitch Counter makes it easy and convenient. Enjoy an uninterrupted experience with no ads or distractions.
                    </Typography>
                    <Typography>
                      For any support or inquiries, please feel free to reach out to us.
                    </Typography>
                </Grid> 
                <Grid item xs={2}
                          sm={4}
                          md={6}>
                  <img src={process.env.PUBLIC_URL + "/projects/stitchcounter/stitchcounter-thumbnail.png"} alt="stitchcountericon" height="600px" />
                </Grid>
              </>
              : 
              <>
                <Grid item xs={4}
                        sm={4}
                        md={6}>
                    <Typography variant="h3" paddingBottom='20px' paddingTop='30px'>
                      Support for Stitch Counter
                    </Typography>
                    <Typography>
                      Stitch Counter is a versatile app designed for knitting, crocheting, and any other counting needs. Whether you're keeping track of stitches in your latest project or counting inventory in your business, Stitch Counter makes it easy and convenient. Enjoy an uninterrupted experience with no ads or distractions.
                    </Typography>
                    <Typography>
                      For any support or inquiries, please feel free to reach out to us.
                    </Typography>
                </Grid> 
                <img src={process.env.PUBLIC_URL + "/projects/stitchcounter/stitchcounter-thumbnail.png"} alt="stitchcountericon" height="300px" />
              </>
              }
          </Grid>
        </Box>
      </section>
      <Contact />
    </>
  );
};

export default SupportPage;